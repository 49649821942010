import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft, Eraser, Undo2, Redo2, XCircle, Wand2 } from 'lucide-react';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import PropTypes from 'prop-types';

const EditionPainting = ({ image, sketchId, handleEditFill, onBack }) => {
    const [isEraser, setIsEraser] = useState(false);
    const [aspectRatio, setAspectRatio] = useState(6/5);
    const [prompt, setPrompt] = useState('');
    const canvasRef = useRef(null);
    const containerRef = useRef(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const img = new Image();
        img.onload = () => {
            const imageAspectRatio = img.naturalWidth / img.naturalHeight;
            setAspectRatio(imageAspectRatio);
        };
        img.src = image;
    }, [image]);

    const toggleEraser = () => {
        setIsEraser(!isEraser);
        if (canvasRef.current) {
            canvasRef.current.eraseMode(!isEraser);
        }
    };

    const generateMask = async () => {
        const canvas = canvasRef.current;
        const maskPaths = await canvas.exportImage('png');
        
        // Create a temporary canvas to process the mask
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
        
        if (!tempCtx) {
            throw new Error('Could not get canvas context');
        }

        // Load mask image
        const maskImg = new Image();
        const maskLoadPromise = new Promise((resolve, reject) => {
            maskImg.onload = resolve;
            maskImg.onerror = () => reject(new Error('Failed to load mask image'));
            maskImg.src = maskPaths;
        });

        await maskLoadPromise;

        // Calculate optimal dimensions
        const MAX_DIMENSION = 2048;
        let width = maskImg.width;
        let height = maskImg.height;

        if (width > MAX_DIMENSION || height > MAX_DIMENSION) {
            const ratio = Math.min(MAX_DIMENSION / width, MAX_DIMENSION / height);
            width *= ratio;
            height *= ratio;
        }

        // Set canvas dimensions and process mask
        tempCanvas.width = width;
        tempCanvas.height = height;
        tempCtx.drawImage(maskImg, 0, 0, width, height);
        const imageData = tempCtx.getImageData(0, 0, width, height);
        const data = imageData.data;

        // Process image data to create binary mask
        for (let i = 0; i < data.length; i += 4) {
            if (data[i] !== 0 || data[i + 1] !== 0 || data[i + 2] !== 0) {
                data[i] = data[i + 1] = data[i + 2] = 255;
                data[i + 3] = 255;
            }
        }

        tempCtx.putImageData(imageData, 0, 0);
        const processedMask = tempCanvas.toDataURL('image/jpeg', 0.85);
        return processedMask;
    }

    const handleEnhance = async () => {
        try {
            if (!prompt.trim()) {
                setError('Please provide a prompt for the enhancement');
                return;
            }

            setIsProcessing(true);
            setError(null);

            const processedMask = await generateMask();

            // Send to backend with new versioning support
            await handleEditFill({
                prompt,
                baseImage: image,
                maskImage: processedMask,
                parentId: sketchId
            });

            onBack();


        } catch (err) {
            setError(err.message || 'Failed to enhance the sketch');
            console.error('Error in handleEnhance:', err);
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
            <div className="container mx-auto h-screen flex items-center justify-center p-4">
                <div className="relative w-full h-[calc(100%-8rem)]">
                    {/* Back Button */}
                    <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3, delay: 0.2 }}
                        className="absolute -top-16 left-0 z-10"
                    >
                        <button onClick={onBack} className="flex items-center gap-2 text-gray-600 hover:text-gray-800 transition-colors group">
                            <ArrowLeft className="w-6 h-6 group-hover:-translate-x-1 transition-transform" />
                            <span className="font-children text-lg">Back to Options</span>
                        </button>
                    </motion.div>

                    <div className="flex gap-8 h-full">
                        {/* Left side - Canvas and Tools */}
                        <div className="flex-1 flex flex-col gap-4">
                            {/* Canvas Container with centering wrapper */}
                            <div className="flex justify-center items-start h-[calc(100%-5rem)]">
                                <motion.div
                                    layoutId="artwork-container"
                                    ref={containerRef}
                                    className="bg-white rounded-xl shadow-xl overflow-hidden"
                                    style={{ 
                                        width: 'auto',
                                        height: '100%',
                                        aspectRatio: aspectRatio
                                    }}
                                >
                                    <motion.div
                                        layoutId="artwork-image"
                                        className="w-full h-full"
                                    >
                                        <ReactSketchCanvas
                                            ref={canvasRef}
                                            width="100%"
                                            height="100%"
                                            strokeWidth={48}
                                            eraserWidth={60}
                                            strokeColor="rgba(0, 0, 255, 0.75)"
                                            backgroundImage={image}
                                            exportWithBackgroundImage={false}    
                                            className="rounded-xl"
                                        />
                                    </motion.div>
                                </motion.div>
                            </div>

                            {/* Drawing Tools */}
                            <motion.div 
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.3 }}
                                className="flex justify-center gap-4 py-2"
                            >
                                <button
                                    onClick={toggleEraser}
                                    className={`p-3 rounded-lg transition-all ${
                                        isEraser 
                                        ? 'bg-blue-100 text-blue-600 shadow-inner border border-blue-200' 
                                        : 'bg-white text-gray-600 hover:text-gray-800 shadow-md'
                                    }`}
                                    title={isEraser ? "Eraser Mode (Active)" : "Eraser Mode"}
                                >
                                    <Eraser className="w-6 h-6" />
                                </button>
                                <button
                                    onClick={() => canvasRef.current?.undo()}
                                    className="p-3 bg-white rounded-lg shadow-md text-gray-600 hover:text-gray-800 transition-colors"
                                    title="Undo"
                                >
                                    <Undo2 className="w-6 h-6" />
                                </button>
                                <button
                                    onClick={() => canvasRef.current?.redo()}
                                    className="p-3 bg-white rounded-lg shadow-md text-gray-600 hover:text-gray-800 transition-colors"
                                    title="Redo"
                                >
                                    <Redo2 className="w-6 h-6" />
                                </button>
                                <button
                                    onClick={() => canvasRef.current?.clearCanvas()}
                                    className="p-3 bg-white rounded-lg shadow-md text-gray-600 hover:text-gray-800 transition-colors"
                                    title="Clear Drawing"
                                >
                                    <XCircle className="w-6 h-6" />
                                </button>
                            </motion.div>
                        </div>

                        {/* Right side - Prompt and Enhance */}
                        <div className="w-64 flex flex-col gap-4">
                            <div className="bg-white p-4 rounded-xl shadow-xl">
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Enhancement Prompt
                                </label>
                                <textarea
                                    value={prompt}
                                    onChange={(e) => setPrompt(e.target.value)}
                                    className="w-full h-32 p-2 border rounded-lg resize-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    placeholder="Describe how you want to enhance the marked areas..."
                                />
                                <button
                                    onClick={handleEnhance}
                                    disabled={isProcessing}
                                    className={`w-full mt-4 flex items-center justify-center gap-2 ${
                                        isProcessing 
                                            ? 'bg-blue-300 cursor-not-allowed' 
                                            : 'bg-blue-500 hover:bg-blue-600'
                                    } text-white px-4 py-2 rounded-lg transition-colors`}
                                >
                                    <Wand2 className="w-5 h-5" />
                                    <span>{isProcessing ? 'Processing...' : 'Enhance'}</span>
                                </button>
                                {error && (
                                    <p className="mt-2 text-sm text-red-500">{error}</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           
        </div>
    );
};

EditionPainting.propTypes = {
    image: PropTypes.string.isRequired,
    sketchId: PropTypes.string.isRequired,
    onBack: PropTypes.func.isRequired,
    handleEditFill: PropTypes.func.isRequired
};

export default EditionPainting;
