import { openDB } from 'idb';

const DB_NAME = 'AuthDB';
const DB_VERSION = 1;
const AUTH_STORE = 'auth';
const USER_STORE = 'user';

// Initialize IndexedDB
const initDB = async () => {
  return openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      // Create/recreate auth store
      if (db.objectStoreNames.contains(AUTH_STORE)) {
        db.deleteObjectStore(AUTH_STORE);
      }
      if (db.objectStoreNames.contains(USER_STORE)) {
        db.deleteObjectStore(USER_STORE);
      }

      // Auth store for tokens
      const authStore = db.createObjectStore(AUTH_STORE);
      authStore.put(null, 'refreshToken');
      authStore.put(null, 'accessToken');

      // User store for user data
      const userStore = db.createObjectStore(USER_STORE);
      userStore.put(null, 'userData');
      userStore.put(0, 'credits');
    },
  });
};

// Token-related operations
export const saveRefreshToken = async (token) => {
  try {
    const db = await initDB();
    const tx = db.transaction(AUTH_STORE, 'readwrite');
    const store = tx.objectStore(AUTH_STORE);
    await store.put(token, 'refreshToken');
    await tx.done;
  } catch (error) {
    console.error('Error saving refresh token:', error);
    throw error;
  }
};

export const getRefreshToken = async () => {
  try {
    const db = await initDB();
    const tx = db.transaction(AUTH_STORE, 'readonly');
    const store = tx.objectStore(AUTH_STORE);
    const token = await store.get('refreshToken');
    await tx.done;
    return token;
  } catch (error) {
    console.error('Error getting refresh token:', error);
    return null;
  }
};

export const saveAccessToken = async (token) => {
  try {
    const db = await initDB();
    const tx = db.transaction(AUTH_STORE, 'readwrite');
    const store = tx.objectStore(AUTH_STORE);
    await store.put(token, 'accessToken');
    await tx.done;
  } catch (error) {
    console.error('Error saving access token:', error);
    throw error;
  }
};

export const getAccessToken = async () => {
  try {
    const db = await initDB();
    const tx = db.transaction(AUTH_STORE, 'readonly');
    const store = tx.objectStore(AUTH_STORE);
    const token = await store.get('accessToken');
    await tx.done;
    return token;
  } catch (error) {
    console.error('Error getting access token:', error);
    return null;
  }
};

// User data operations
export const saveUserData = async (userData) => {
  try {
    const db = await initDB();
    const tx = db.transaction(USER_STORE, 'readwrite');
    const store = tx.objectStore(USER_STORE);
    await store.put(userData, 'userData');
    await tx.done;
  } catch (error) {
    console.error('Error saving user data:', error);
    throw error;
  }
};

export const getUserData = async () => {
  try {
    const db = await initDB();
    const tx = db.transaction(USER_STORE, 'readonly');
    const store = tx.objectStore(USER_STORE);
    const userData = await store.get('userData');
    await tx.done;
    return userData;
  } catch (error) {
    console.error('Error getting user data:', error);
    return null;
  }
};

export const updateUserCredits = async (credits) => {
  try {
    const db = await initDB();
    const tx = db.transaction(USER_STORE, 'readwrite');
    const store = tx.objectStore(USER_STORE);
    await store.put(credits, 'credits');
    await tx.done;
  } catch (error) {
    console.error('Error updating user credits:', error);
    throw error;
  }
};

export const getUserCredits = async () => {
  try {
    const db = await initDB();
    const tx = db.transaction(USER_STORE, 'readonly');
    const store = tx.objectStore(USER_STORE);
    const credits = await store.get('credits');
    await tx.done;
    return credits;
  } catch (error) {
    console.error('Error getting user credits:', error);
    return 0;
  }
};

// Clear all auth data
export const clearAuthData = async () => {
  try {
    const db = await initDB();
    // Create a single transaction for both stores
    const tx = db.transaction([AUTH_STORE, USER_STORE], 'readwrite');
    
    const authStore = tx.objectStore(AUTH_STORE);
    const userStore = tx.objectStore(USER_STORE);
    
    // Queue up all operations
    const ops = [
      authStore.put(null, 'refreshToken'),
      authStore.put(null, 'accessToken'),
      userStore.put(null, 'userData'),
      userStore.put(0, 'credits')
    ];
    
    // Wait for all operations to complete
    await Promise.all(ops);
    // Wait for transaction to complete
    await tx.done;
  } catch (error) {
    console.error('Error clearing auth data:', error);
    throw error;
  }
}; 