import React from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Sparkles, Loader, AlertCircle } from 'lucide-react';
import ScribbleText from '../UI/ui_scribble_text.component';

const CreationPageInput = ({
    prompt, 
    setPrompt, 
    selectedMode, 
    setSelectedMode,
    selectedOrientation,
    setSelectedOrientation, 
    handleGenerate, 
    isLoading,
    error,
    remainingCredits 
}) => {
    const { t } = useTranslation();

    const modes = [
        { id: 'simple', label: 'Simple', description: 'Basic line art style' },
        { id: 'good', label: 'Good', description: 'Enhanced details and shading' },
        { id: 'advanced', label: 'Advanced', description: 'Complex artistic style' }
    ];

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey) {
            e.preventDefault();
            handleGenerate();
        }
    };

    return (
        <div className="max-w-7xl mx-auto px-4 py-8">
            {/* Header */}
            <div className="text-sm font-medium text-gray-600 mb-8">
                {remainingCredits !== null && (
                    <span>Credits remaining: {remainingCredits}</span>
                )}
            </div>

            <motion.div 
                className="text-center mb-12"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
            >
                <div className="flex justify-center mb-4">
                    <ScribbleText
                        text={t('sketchbook.title')}
                        sizeFactor={0.6}
                        fillColor="#027a9f"
                        strokeColor="#00a4d7"
                        roughness={0.6}
                        strokeWidth={1.5}
                        animate={true}
                    />
                </div>
            </motion.div>

            {/* Error Display */}
            <AnimatePresence>
                {error && (
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        className="max-w-2xl mx-auto mb-4 p-4 bg-red-50 border border-red-200 rounded-lg flex items-center gap-3"
                    >
                        <AlertCircle className="w-5 h-5 text-red-500" />
                        <span className="text-red-600 font-medium">{error}</span>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Prompt Input */}
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="max-w-2xl mx-auto mb-12"
            >
                <div className="relative">
                    <textarea
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        onKeyDown={handleKeyPress}
                        placeholder="Describe what you want to draw..."
                        className="w-full px-6 py-4 pb-16 bg-white rounded-xl shadow-md 
                                 border-2 border-gray-100 focus:border-blue-300 
                                 focus:ring-2 focus:ring-blue-200 focus:outline-none
                                 font-children text-lg transition-all duration-200
                                 resize-none h-40"
                        disabled={isLoading}
                    />
                    <Sparkles className="absolute right-4 top-4 text-blue-400 w-5 h-5" />
                    
                    {/* Generate Button */}
                    <button
                        onClick={handleGenerate}
                        disabled={isLoading || !prompt.trim()}
                        className={`absolute bottom-4 right-4
                                  px-6 py-2 rounded-lg transition-all duration-200 
                                  bg-blue-500 text-white shadow-md hover:shadow-lg
                                  font-children text-lg font-semibold
                                  disabled:opacity-50 disabled:cursor-not-allowed
                                  flex items-center justify-center gap-2`}
                    >
                        {isLoading ? (
                            <>
                                <Loader className="w-5 h-5 animate-spin" />
                                {t('common.generating')}
                            </>
                        ) : (
                            <>
                                <Sparkles className="w-5 h-5" />
                                {t('common.generate')}
                            </>
                        )}
                    </button>
                </div>
            </motion.div>

            {/* Mode Selection */}
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
                className="max-w-2xl mx-auto mb-8"
            >
                <div className="grid grid-cols-3 gap-4">
                    {modes.map((mode) => (
                        <button
                            key={mode.id}
                            onClick={() => setSelectedMode(mode.id)}
                            disabled={isLoading}
                            className={`p-4 rounded-xl transition-all duration-200 
                                      ${selectedMode === mode.id 
                                        ? 'bg-blue-500 text-white shadow-lg scale-105' 
                                        : 'bg-white text-gray-700 shadow-md hover:shadow-lg hover:scale-102'}
                                      ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                            <div className="font-children font-semibold text-lg mb-1">
                                {mode.label}
                            </div>
                            <div className={`text-sm ${selectedMode === mode.id ? 'text-blue-100' : 'text-gray-500'}`}>
                                {mode.description}
                            </div>
                        </button>
                    ))}
                </div>
            </motion.div>

            {/* Orientation Selection */}
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="max-w-2xl mx-auto mb-8"
            >
                <div className="flex justify-center gap-4">
                    <button
                        onClick={() => setSelectedOrientation('portrait')}
                        disabled={isLoading}
                        className={`p-4 rounded-xl transition-all duration-200 w-40
                                  ${selectedOrientation === 'portrait'
                                    ? 'bg-blue-500 text-white shadow-lg scale-105'
                                    : 'bg-white text-gray-700 shadow-md hover:shadow-lg hover:scale-102'}
                                  ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        <div className="flex flex-col items-center">
                            <div className={`w-12 h-16 border-2 rounded-lg mb-2 flex items-center justify-center
                                          ${selectedOrientation === 'portrait' ? 'border-white' : 'border-gray-400'}`}>
                                <div className="w-8 h-12 bg-current rounded"></div>
                            </div>
                            <span className="font-children font-semibold">Portrait</span>
                        </div>
                    </button>

                    <button
                        onClick={() => setSelectedOrientation('landscape')}
                        disabled={isLoading}
                        className={`p-4 rounded-xl transition-all duration-200 w-40
                                  ${selectedOrientation === 'landscape'
                                    ? 'bg-blue-500 text-white shadow-lg scale-105'
                                    : 'bg-white text-gray-700 shadow-md hover:shadow-lg hover:scale-102'}
                                  ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        <div className="flex flex-col items-center">
                            <div className={`w-16 h-12 border-2 rounded-lg mb-2 flex items-center justify-center
                                          ${selectedOrientation === 'landscape' ? 'border-white' : 'border-gray-400'}`}>
                                <div className="w-12 h-8 bg-current rounded"></div>
                            </div>
                            <span className="font-children font-semibold">Landscape</span>
                        </div>
                    </button>
                </div>
            </motion.div>
        </div>
    );
};

CreationPageInput.propTypes = {
    prompt: PropTypes.string.isRequired,
    setPrompt: PropTypes.func.isRequired,
    selectedMode: PropTypes.string.isRequired,
    setSelectedMode: PropTypes.func.isRequired,
    selectedOrientation: PropTypes.oneOf(['portrait', 'landscape']).isRequired,
    setSelectedOrientation: PropTypes.func.isRequired,
    handleGenerate: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    remainingCredits: PropTypes.number
};

export default CreationPageInput;
