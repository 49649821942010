import { useState, useEffect, useCallback } from 'react';
import api from '../ApiHandler';
import { 
    getPageSketches,
    upsertSketches,
    getLatestSketchTimestamp,
    getStaleSketches,
    searchSketches,
    DEFAULT_ITEMS_PER_PAGE,
    DEFAULT_PAGE,
    isUrlStale,
    updateGalleryMetadata,
    clearCache
} from '../../services/db.service';

// Get initial page from URL query parameters
const getInitialPage = () => {
    const params = new URLSearchParams(window.location.search);
    const page = parseInt(params.get('page'));
    return !isNaN(page) && page > 0 ? page : DEFAULT_PAGE;
};

// Get initial search query from URL
const getInitialSearch = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('search') || '';
};

// Update URL with current state
const updateUrl = (page, search) => {
    const url = new URL(window.location.href);
    
    // Update search parameter
    if (search) {
        url.searchParams.set('search', search);
    } else {
        url.searchParams.delete('search');
    }
    
    // Update page parameter
    if (page === DEFAULT_PAGE) {
        url.searchParams.delete('page');
    } else {
        url.searchParams.set('page', page.toString());
    }
    
    window.history.pushState({}, '', url.toString());
};

// Simple retry utility
const fetchWithRetry = async (fn, maxRetries = 3) => {
    for (let i = 0; i < maxRetries; i++) {
        try {
            return await fn();
        } catch (error) {
            if (i === maxRetries - 1) throw error;
            await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, i)));
        }
    }
};

const useGallery = (itemsPerPage = DEFAULT_ITEMS_PER_PAGE) => {
    const [loading, setLoading] = useState(true);
    const [sketches, setSketches] = useState([]);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState(getInitialSearch);
    const [pageInfo, setPageInfo] = useState({ totalPages: 1, totalItems: 0, currentPage: DEFAULT_PAGE, itemsPerPage });
    const [currentPage, setCurrentPage] = useState(getInitialPage);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    // Validate page number against total pages
    const validatePage = useCallback((page, totalPages) => {
        if (page < 1) return DEFAULT_PAGE;
        if (totalPages && page > totalPages) return totalPages;
        return page;
    }, []);

    // Update search query with URL update
    const handleSearchChange = useCallback((query) => {
        setSearchQuery(query);
        if (currentPage !== DEFAULT_PAGE) {
            setCurrentPage(DEFAULT_PAGE);
        }
        updateUrl(DEFAULT_PAGE, query);
    }, [currentPage]);

    // Update page with URL update
    const handlePageChange = useCallback((page) => {
        const validPage = validatePage(page, pageInfo.totalPages);
        if (validPage !== currentPage) {
            setCurrentPage(validPage);
            updateUrl(validPage, searchQuery);
        }
    }, [searchQuery, pageInfo.totalPages, currentPage, validatePage]);

    // Check for new sketches and update cache
    const checkForNewSketches = useCallback(async () => {
        try {
            const latestTimestamp = await getLatestSketchTimestamp();
            const response = await fetchWithRetry(() => 
                api.get('image/sketch/check-updates', {
                    params: { lastUpdate: latestTimestamp }
                })
            );

            if (response.data.sketches?.length) {
                // Update sketches in cache
                await upsertSketches(response.data.sketches);
                
                // Update metadata with new total counts
                if (response.data.totalItems !== undefined) {
                    const totalItems = response.data.totalItems;
                    const totalPages = Math.ceil(totalItems / itemsPerPage);
                    console.log('totalItems', totalItems);
                    console.log('totalPages', totalPages);
                    await updateGalleryMetadata({
                        totalItems,
                        totalPages
                    });
                }
                
                return true;
            }
            return false;
        } catch (error) {
            console.error('Error checking for new sketches:', error);
            return false;
        }
    }, []);

    // Refresh stale URLs
    const refreshStaleUrls = useCallback(async () => {
        try {
            const staleSketches = await getStaleSketches();
            if (!staleSketches.length) return;

            const response = await fetchWithRetry(() => 
                api.post('image/sketch/refresh-urls', {
                    sketchIds: staleSketches.map(sketch => sketch._id)
                })
            );

            if (response.data.sketches?.length) {
                await upsertSketches(response.data.sketches);
                return true;
            }
            return false;
        } catch (error) {
            console.error('Error refreshing stale URLs:', error);
            return false;
        }
    }, []);

    // Initial data load from API
    const loadInitialData = useCallback(async () => {
        try {
            // First, check if we have any cached data for the current page
            let cachedResult;
            if (!searchQuery) {
                cachedResult = await getPageSketches(currentPage, itemsPerPage);
                if (cachedResult.sketches.length > 0 && 
                    cachedResult.sketches.every(sketch => sketch.presignedUrl && !isUrlStale(sketch.fetchedAt))) {
                    // If we have valid cached data, use it
                    return cachedResult;
                }
            }

            // If no valid cache or searching, load from API
            const response = await fetchWithRetry(() => 
                api.get('image/sketch/list', {
                    params: { 
                        page: currentPage,
                        limit: itemsPerPage,
                        search: searchQuery,
                        loadAdjacent: 'true', // Load current page plus adjacent pages for smoother navigation
                        // Only skip URL signing if we have valid cached URLs
                        skipUrlSigning: false
                    }
                })
            );

            const { 
                sketches: newSketches, 
                totalPages, 
                totalItems, 
                filteredItems,
                loadedPages 
            } = response.data;
            
            // Update metadata in IndexedDB
            await updateGalleryMetadata({ totalItems, totalPages });

            // Merge with cached URLs if available and valid
            const sketchesWithUrls = newSketches.map(sketch => {
                if (!sketch.presignedUrl && cachedResult?.sketches?.length > 0) {
                    const cachedSketch = cachedResult.sketches.find(s => s._id === sketch._id);
                    if (cachedSketch?.presignedUrl && !isUrlStale(cachedSketch.fetchedAt)) {
                        return {
                            ...sketch,
                            presignedUrl: cachedSketch.presignedUrl,
                            fetchedAt: cachedSketch.fetchedAt
                        };
                    }
                }
                return sketch;
            });

            // Update IndexedDB cache with the merged sketches
            await upsertSketches(sketchesWithUrls);

            // Return only the current page's sketches
            const currentPageSketches = loadedPages 
                ? sketchesWithUrls.slice(
                    (currentPage - loadedPages.start) * itemsPerPage,
                    (currentPage - loadedPages.start + 1) * itemsPerPage
                )
                : sketchesWithUrls;

            return {
                sketches: currentPageSketches,
                pageInfo: {
                    totalPages,
                    totalItems,
                    filteredItems,
                    currentPage,
                    itemsPerPage
                }
            };
        } catch (error) {
            console.error('Error loading initial data:', error);
            throw error;
        }
    }, [currentPage, itemsPerPage, searchQuery]);

    // Fetch sketches from cache or API
    const fetchSketches = useCallback(async () => {
        try {
            setLoading(true);
            setError(null);

            let result;
            
            // On initial load or when not searching, get data from API
            if (isInitialLoad || !searchQuery) {
                result = await loadInitialData();
                setIsInitialLoad(false);
            } else {
                // When searching, use local cache
                result = await searchSketches(searchQuery, currentPage, itemsPerPage);
            }

            // Update state with data
            setSketches(result.sketches);
            
            // Safely construct pageInfo with fallbacks
            const newPageInfo = {
                currentPage: currentPage,
                itemsPerPage,
                totalItems: searchQuery 
                    ? (result.pageInfo?.filteredItems || 0) 
                    : (result.pageInfo?.totalItems || 0),
                totalPages: searchQuery 
                    ? Math.ceil((result.pageInfo?.filteredItems || 0) / itemsPerPage)
                    : (result.pageInfo?.totalPages || 1),
                ...result.pageInfo
            };
            
            setPageInfo(newPageInfo);

            // Validate current page
            const validatedPage = validatePage(
                currentPage, 
                newPageInfo.totalPages
            );
            
            if (validatedPage !== currentPage) {
                handlePageChange(validatedPage);
                return;
            }

            // Check for updates in background
            const [hasNewSketches, hasUpdatedUrls] = await Promise.all([
                checkForNewSketches(),
                refreshStaleUrls()
            ]);

            // If we got updates, refresh the view
            if (hasNewSketches || hasUpdatedUrls) {
                const updatedResult = searchQuery
                    ? await searchSketches(searchQuery, currentPage, itemsPerPage)
                    : await getPageSketches(currentPage, itemsPerPage);
                
                setSketches(updatedResult.sketches);
                
                // Safely construct updated pageInfo
                const updatedPageInfo = {
                    currentPage: currentPage,
                    itemsPerPage,
                    totalItems: searchQuery 
                        ? (updatedResult.pageInfo?.filteredItems || 0) 
                        : (result.pageInfo?.totalItems || 0),
                    totalPages: searchQuery 
                        ? Math.ceil((updatedResult.pageInfo?.filteredItems || 0) / itemsPerPage)
                        : (result.pageInfo?.totalPages || 1),
                    ...updatedResult.pageInfo
                };
                
                setPageInfo(updatedPageInfo);
            }
        } catch (error) {
            console.error('Error fetching sketches:', error);
            setError(error.response?.data?.error || 'Failed to fetch sketches');
        } finally {
            setLoading(false);
        }
    }, [
        currentPage,
        itemsPerPage,
        searchQuery,
        validatePage,
        handlePageChange,
        checkForNewSketches,
        refreshStaleUrls,
        isInitialLoad,
        loadInitialData
    ]);

    // Effect for handling page changes and search
    useEffect(() => {
        fetchSketches();
    }, [currentPage, searchQuery, fetchSketches]);

    // Listen for browser back/forward navigation
    useEffect(() => {
        const handlePopState = () => {
            const newPage = getInitialPage();
            const newSearch = getInitialSearch();
            
            if (newSearch !== searchQuery) {
                setSearchQuery(newSearch);
            }
            
            const validatedPage = validatePage(newPage, pageInfo.totalPages);
            if (validatedPage !== currentPage) {
                setCurrentPage(validatedPage);
            }
        };

        window.addEventListener('popstate', handlePopState);
        return () => window.removeEventListener('popstate', handlePopState);
    }, [currentPage, searchQuery, pageInfo.totalPages, validatePage]);

    // Hard refresh function that clears cache and reloads everything
    const hardRefresh = useCallback(async () => {
        try {
            setLoading(true);
            setError(null);
            
            // Clear the IndexedDB cache
            await clearCache();
            
            // Reset to initial state
            setIsInitialLoad(true);
            setCurrentPage(DEFAULT_PAGE);
            setSearchQuery('');
            updateUrl(DEFAULT_PAGE, '');
            
            // Fetch fresh data from API
            const result = await loadInitialData();
            
            // Update state with fresh data
            setSketches(result.sketches);
            setPageInfo({
                currentPage: DEFAULT_PAGE,
                itemsPerPage,
                totalItems: result.pageInfo?.totalItems || 0,
                totalPages: result.pageInfo?.totalPages || 1,
                ...result.pageInfo
            });
        } catch (error) {
            console.error('Error performing hard refresh:', error);
            setError(error.response?.data?.error || 'Failed to refresh gallery');
        } finally {
            setLoading(false);
        }
    }, [loadInitialData, itemsPerPage]);

    return {
        loading,
        error,
        sketches,
        searchQuery,
        setSearchQuery: handleSearchChange,
        pageInfo,
        currentPage,
        setCurrentPage: handlePageChange,
        refresh: useCallback(() => fetchSketches(), [fetchSketches]),
        hardRefresh
    };
};

export default useGallery;
