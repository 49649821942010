import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { handleLogout, switchAccount } from '../../Hooks/LoginHandler';
import LanguageChange from './language_change.component';
import { LogOut, LogIn, SwitchCamera, UserCircle } from 'lucide-react';
import ScribbleText from '../UI/ui_scribble_text.component';
const NavLink = ({ to, icon: Icon, children, className = '' }) => {
    const location = useLocation();
    const isActive = location.pathname === to;
    return (
        <Link
            to={to}
            className={`flex items-center px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200
                ${isActive 
                    ? 'bg-blue-100 text-[#027a9f]' 
                    : 'text-[#027a9f] bg-[#00a4d7]/5'
                } ${className}`}
        >
            {Icon && <Icon className={`w-4 h-4 mr-2 ${isActive ? 'text-blue-600' : 'text-gray-500'}`} />}
            {children}
        </Link>
    );
};

NavLink.propTypes = {
    to: PropTypes.string.isRequired,
    icon: PropTypes.elementType,
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};

const UserSection = ({ userData, t }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    if (!userData) {
        return (
            <NavLink 
                to="/login" 
                icon={LogIn}
                className="border-2 border-blue-200 hover:border-blue-300 hover:bg-blue-50"
            >
                {t('login.login')}
            </NavLink>
        );
    }

    return (
        <div className="relative" ref={dropdownRef}>
            <button
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="flex items-center gap-2 px-3 py-2 rounded-lg hover:bg-gray-100 transition-all duration-200"
            >
                <UserCircle className="w-5 h-5 text-black" />
                <span className="text-sm text-black ">{userData.email}</span>
                <svg 
                    className={`w-4 h-4 text-gray-500 transition-transform duration-200 ${isDropdownOpen ? 'rotate-180' : ''}`}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
            </button>

            {/* Dropdown Menu */}
            {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg border border-gray-200 py-2 z-50">
                    <Link
                        to="/profile"
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-200"
                        onClick={() => setIsDropdownOpen(false)}
                    >
                        <UserCircle className="w-4 h-4 mr-2" />
                        {t('login.profile')}
                    </Link>
                    <button
                        onClick={() => {
                            switchAccount();
                            setIsDropdownOpen(false);
                        }}
                        className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-200"
                    >
                        <SwitchCamera className="w-4 h-4 mr-2" />
                        {t('login.switch-account')}
                    </button>
                    <div className="h-px bg-gray-200 my-1" />
                    <div className="px-4 py-2">
                        <LanguageChange inDropdown={true} />
                    </div>
                    <div className="h-px bg-gray-200 my-1" />
                    <button
                        onClick={() => {
                            handleLogout(true);
                            setIsDropdownOpen(false);
                        }}
                        className="flex items-center w-full px-4 py-2 text-sm text-red-600 hover:bg-red-50 transition-colors duration-200"
                    >
                        <LogOut className="w-4 h-4 mr-2" />
                        {t('login.logout')}
                    </button>
                </div>
            )}
        </div>
    );
};

UserSection.propTypes = {
    userData: PropTypes.object,
    t: PropTypes.func.isRequired
};

const Logo = React.memo(() => {
    return (
        <div className="transform-gpu scale-[0.35] origin-left">
        <ScribbleText
            text="Crayons"
            sizeFactor={1}
            fillColor="white"
            strokeColor="#00a4d7"
            roughness={1.2}
            strokeWidth={5}
            animate={false}
        />
    </div>
    );
});

Logo.displayName = 'Logo';

const DesktopNav = ({ t, userData, isCheckingAuth }) => {
    if (isCheckingAuth) {
        return (
            <nav className="bg-white/80 backdrop-blur-sm border-b border-gray-200">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-end h-16">
                        <div className="flex items-center">
                            <LanguageChange />
                        </div>
                    </div>
                </div>
            </nav>
        );
    }

    return (
        <nav className="bg-white/80 backdrop-blur-sm border-b border-gray-200 h-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full">
                <div className="flex justify-between h-full">
                    {/* Left section - Main navigation */}
                    <div className="flex items-center gap-6">
                        <Link 
                            to="/" 
                            className="flex items-center px-4 py-2 transition-colors duration-200 h-full mr-4"
                        >
                            <Logo />
                        </Link>
                        {userData && (
                            <>
                                <NavLink to="/gallery" className="px-6">
                                    {t('login.my-gallery')}
                                </NavLink>
                                <NavLink to="/create" className="px-6">
                                    {t('login.create')}
                                </NavLink>
                            </>
                        )}
                    </div>

                    {/* Right section - User controls */}
                    <div className="flex items-center">
                        <UserSection userData={userData} t={t} />
                    </div>
                </div>
            </div>
        </nav>
    );
};

DesktopNav.propTypes = {
    t: PropTypes.func.isRequired,
    userData: PropTypes.object,
    isCheckingAuth: PropTypes.bool.isRequired
};

export default withTranslation()(DesktopNav); 