import React, { useState, useMemo } from 'react';
import { motion } from 'framer-motion';
import { RefreshCw, Plus, Search, ChevronLeft, ChevronRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { MasonryPhotoAlbum } from "react-photo-album";
import { useInView } from 'react-intersection-observer';
import useGallery from '../../Hooks/Sketchbook/useGallery';
import useSketchbook from '../../Hooks/Sketchbook/useSketchbook';
import CreationPageEdition from '../Sketchbook/CreationPageEdition.component';
import { GALLERY_CONFIG } from '../../config/gallery.config';
import ScribbleText from '../UI/ui_scribble_text.component';
import "react-photo-album/masonry.css";

// Error Boundary Component
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error('Gallery Error:', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="text-center py-8">
                    <h2 className="text-xl font-children font-semibold text-gray-800 mb-2">
                        Something went wrong
                    </h2>
                    <button
                        onClick={() => this.setState({ hasError: false })}
                        className="text-blue-500 hover:text-blue-600"
                    >
                        Try again
                    </button>
                </div>
            );
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired
};

// Lazy load the photo component for better performance
const PhotoComponent = React.memo(({ photo, imageProps: { alt, style, ...restImageProps }, width, height }) => (
    <motion.div
        className="relative bg-blue-50 rounded-lg shadow-sm overflow-hidden cursor-pointer group"
        whileHover={{ y: -2, scale: 1.02 }}
        style={{ 
            ...style,
            margin: '8px 0',
            width,
            height,
        }}
        layoutId={`photo-${photo._id}`}
    >
        <img
            src={photo.src}
            {...restImageProps}
            alt={alt || photo.prompt}
            style={{
                ...style,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '8px',
            }}
            loading="lazy"
        />
        {/* Title overlay - only visible on hover */}
        <div className="absolute inset-x-0 bottom-0 bg-black/70 backdrop-blur-sm py-2 px-3 
                      transform translate-y-full group-hover:translate-y-0 transition-transform duration-200">
            <h3 className="font-children text-base font-semibold text-white mb-1.5 truncate">
                {photo.prompt}
            </h3>
        </div>
        {/* Date - always visible */}
        <div className="absolute top-0 left-0 transition-all duration-200 group-hover:scale-110 group-hover:m-1">
            <div className="relative px-1 py-0 transition-all duration-200 group-hover:px-2 group-hover:py-1">
                {/* Background */}
                <div className="absolute inset-0 bg-white transition-all duration-200 group-hover:bg-white/90 group-hover:shadow-md" />
                <span className="relative text-xs font-medium text-emerald-600 transition-all duration-200 group-hover:text-sm group-hover:font-semibold">
                    {new Date(photo.createdAt).toLocaleDateString()}
                </span>
            </div>
        </div>
    </motion.div>
));

PhotoComponent.displayName = 'PhotoComponent';

PhotoComponent.propTypes = {
    photo: PropTypes.shape({
        src: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        prompt: PropTypes.string,
        createdAt: PropTypes.string,
        _id: PropTypes.string.isRequired,
        isCreateButton: PropTypes.bool,
    }).isRequired,
    imageProps: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
};

// Add this new component after the PhotoComponent
const CreateNewButton = React.memo(({ imageProps: { style }, width, height }) => (
    <motion.div
        className="relative bg-white rounded-lg shadow-sm overflow-hidden cursor-pointer"
        whileHover={{ y: -2, scale: 1.02 }}
        style={{
            ...style,
            margin: '8px 0',
            width,
            height,
        }}
    >
        <motion.a
            href="/create"
            className="absolute inset-0 flex flex-col items-center justify-center p-6 group"
        >
            <div className="absolute inset-3 border-2 border-dashed border-blue-300 rounded-lg bg-gradient-to-br from-blue-50/50 to-white/90" />
            
            <div className="relative flex flex-col items-center">
                <div className="w-20 h-20 bg-gradient-to-br from-blue-500 to-blue-600 rounded-full flex items-center justify-center mb-6 
                             shadow-lg group-hover:scale-110 group-hover:shadow-xl transition-all duration-300 
                             ring-4 ring-white">
                    <Plus className="w-10 h-10 text-white" />
                </div>
                
                <h3 className="font-children text-2xl font-bold text-gray-800 text-center mb-3 
                             group-hover:text-blue-600 transition-colors">
                    Create New
                </h3>
                <div className="absolute inset-0 bg-blue-500/5 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
            </div>
        </motion.a>
    </motion.div>
));

CreateNewButton.displayName = 'CreateNewButton';

CreateNewButton.propTypes = {
    imageProps: PropTypes.shape({
        style: PropTypes.object,
    }).isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
};

const SketchbookGallery = () => {
    const { t } = useTranslation();
    const { 
        loading, 
        error, 
        sketches, 
        searchQuery, 
        setSearchQuery, 
        refresh,
        hardRefresh,
        pageInfo,
        currentPage,
        setCurrentPage
    } = useGallery(GALLERY_CONFIG.ITEMS_PER_PAGE);

    const { editFillSketch } = useSketchbook();
    const [selectedSketch, setSelectedSketch] = useState(null);

    // Setup intersection observer for the top navigation
    const { ref: topNavRef, inView } = useInView({
        threshold: 0,
        // Add some rootMargin to trigger slightly before the element is out of view
        rootMargin: '-50px 0px 0px 0px'
    });

    // Transform sketches into photo album format with memoization
    const photos = useMemo(() => {
        // Create the "Create New" button photo object
        const createNewButton = {
            src: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7', // Transparent 1x1 gif
            width: 400,
            height: 300,
            isCreateButton: true,
            _id: 'create-new-button'
        };

        // Add real sketches after the create button
        const sketchPhotos = sketches.map(sketch => ({
            src: sketch.presignedUrl,
            width: sketch.orientation === 'portrait' ? 300 : 400,
            height: sketch.orientation === 'portrait' ? 400 : 300,
            prompt: sketch.prompt,
            createdAt: sketch.createdAt,
            _id: sketch._id,
        }));

        return [createNewButton, ...sketchPhotos];
    }, [sketches]);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Generate page numbers array
    const getPageNumbers = () => {
        const pageNumbers = [];
        const totalPages = pageInfo.totalPages;

        // Always show first page
        pageNumbers.push({ number: 1, type: 'number' });

        // Handle the display logic
        if (totalPages <= 7) {
            // If we have 7 or fewer pages, show all of them
            for (let i = 2; i <= totalPages; i++) {
                pageNumbers.push({ number: i, type: 'number' });
            }
        } else {
            // We have more than 7 pages, need to handle ellipsis
            if (currentPage <= 4) {
                // We're near the start
                for (let i = 2; i <= 5; i++) {
                    pageNumbers.push({ number: i, type: 'number' });
                }
                pageNumbers.push({ type: 'ellipsis' });
                pageNumbers.push({ number: totalPages, type: 'number' });
            } else if (currentPage >= totalPages - 3) {
                // We're near the end
                pageNumbers.push({ type: 'ellipsis' });
                for (let i = totalPages - 4; i <= totalPages; i++) {
                    pageNumbers.push({ number: i, type: 'number' });
                }
            } else {
                // We're in the middle
                pageNumbers.push({ type: 'ellipsis' });
                for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                    pageNumbers.push({ number: i, type: 'number' });
                }
                pageNumbers.push({ type: 'ellipsis' });
                pageNumbers.push({ number: totalPages, type: 'number' });
            }
        }

        return pageNumbers;
    };

    const handleClick = (photo) => {
        if (photo.isCreateButton) {
            window.location.href = '/create';
            return;
        }
        const sketch = sketches.find(s => s._id === photo._id);
        setSelectedSketch(sketch);
    };

    const handleBack = () => {
        setSelectedSketch(null);
        refresh(); // Use soft refresh when going back
    };

    const handleEditFill = async (editData) => {
        try {
            await editFillSketch(editData);
            handleBack();
        } catch (error) {
            console.error('Error in handleEditFill:', error);
            throw error;
        }
    };

    // Memoize the breakpoint calculations
    const breakpoints = useMemo(() => ({
        columns: (containerWidth) => {
            if (containerWidth < 640) return 2;
            if (containerWidth < 1024) return 3;
            if (containerWidth < 1280) return 4;
            return 4;
        },
        sizes: {
            size: "100vw",
            sizes: [
                { viewport: "(min-width: 1280px)", size: "1280px" },
                { viewport: "(min-width: 1024px)", size: "1024px" },
                { viewport: "(min-width: 640px)", size: "640px" },
            ]
        }
    }), []);

    if (selectedSketch) {
        return (
            <CreationPageEdition
                generatedImage={selectedSketch.presignedUrl}
                isLoading={false}
                onBack={handleBack}
                sketchId={selectedSketch._id}
                handleEditFill={handleEditFill}
            />
        );
    }

    return (
        <ErrorBoundary>
            <div className="w-full bg-gradient-to-b from-blue-50/80 to-white/30 min-h-screen">
                <div className="w-full py-12 px-4 sm:px-6 lg:px-8">
                    <div className="max-w-7xl mx-auto mb-8">
                        <div className="flex flex-col items-center gap-8 mb-8">
                            <div className="font-children font-bold text-gray-800 -ml-1">
                                <ScribbleText
                                    text={t('login.my-gallery')}
                                    sizeFactor={0.8}
                                    fillColor="#027a9f"
                                    strokeColor="#00a4d7"
                                    roughness={1.3}
                                    strokeWidth={2}
                                    animate={true}
                                />
                            </div>
                            <div className="flex items-center gap-4">
                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder={t('gallery.search')}
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        className="pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:border-blue-300 
                                                 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition-colors
                                                 font-children w-64"
                                    />
                                    <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                                </div>
                                <button
                                    onClick={hardRefresh}
                                    className="p-2 rounded-full bg-white shadow-md hover:shadow-lg transition-all"
                                    title={t('gallery.refresh')}
                                >
                                    <RefreshCw className={`w-5 h-5 text-blue-500 ${loading ? 'animate-spin' : ''}`} />
                                </button>
                            </div>
                        </div>

                        {/* Page Numbers and Top Navigation */}
                        {photos.length > 0 && pageInfo.totalPages > 0 && (
                            <div className="flex flex-col items-center mb-4" ref={topNavRef}>
                                <div className="flex justify-center items-center gap-4 w-full max-w-3xl">
                                    {pageInfo.totalPages > 1 && (
                                        <motion.button
                                            onClick={() => handlePageChange(currentPage - 1)}
                                            disabled={currentPage === 1}
                                            whileHover={currentPage !== 1 ? { scale: 1.05 } : {}}
                                            whileTap={currentPage !== 1 ? { scale: 0.95 } : {}}
                                            className={`p-2 rounded-lg flex items-center justify-center font-children border
                                                ${currentPage === 1 
                                                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed border-gray-200' 
                                                    : 'bg-white text-blue-500 hover:bg-blue-50 shadow-md hover:shadow-lg border-blue-200 hover:border-blue-300'}`}
                                        >
                                            <ChevronLeft className="w-6 h-6" />
                                        </motion.button>
                                    )}

                                    <div className="flex justify-center gap-2 flex-wrap">
                                        {getPageNumbers().map((item, index) => (
                                            item.type === 'ellipsis' ? (
                                                <div 
                                                    key={`ellipsis-${index}`}
                                                    className="w-10 h-10 flex items-center justify-center text-blue-500 font-semibold"
                                                >
                                                    •••
                                                </div>
                                            ) : (
                                                <motion.button
                                                    key={`page-${item.number}`}
                                                    onClick={() => handlePageChange(item.number)}
                                                    whileHover={{ scale: 1.05 }}
                                                    whileTap={{ scale: 0.95 }}
                                                    className={`w-10 h-10 rounded-lg font-children font-semibold transition-colors
                                                              ${currentPage === item.number 
                                                                ? 'bg-blue-500 text-white shadow-md' 
                                                                : 'bg-white text-blue-500 hover:bg-blue-50 shadow-sm'}`}
                                                >
                                                    {item.number}
                                                </motion.button>
                                            )
                                        ))}
                                    </div>

                                    {pageInfo.totalPages > 1 && (
                                        <motion.button
                                            onClick={() => handlePageChange(currentPage + 1)}
                                            disabled={currentPage === pageInfo.totalPages}
                                            whileHover={currentPage !== pageInfo.totalPages ? { scale: 1.05 } : {}}
                                            whileTap={currentPage !== pageInfo.totalPages ? { scale: 0.95 } : {}}
                                            className={`p-2 rounded-lg flex items-center justify-center font-children border
                                                ${currentPage === pageInfo.totalPages 
                                                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed border-gray-200' 
                                                    : 'bg-white text-blue-500 hover:bg-blue-50 shadow-md hover:shadow-lg border-blue-200 hover:border-blue-300'}`}
                                        >
                                            <ChevronRight className="w-6 h-6" />
                                        </motion.button>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="max-w-7xl mx-auto">
                        {error && (
                            <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-4">
                                {error}
                            </div>
                        )}
                        
                        {loading ? (
                            <div className="animate-pulse grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {Array.from({ length: 12 }).map((_, i) => (
                                    <div key={i} className="bg-gray-200 h-64 rounded-lg"></div>
                                ))}
                            </div>
                        ) : photos.length > 0 ? (
                            <div className="space-y-6">
                                <div className="bg-blue-100 p-4 rounded-lg shadow-sm">
                                    <div className="columns-photo-album">
                                        <MasonryPhotoAlbum
                                            photos={photos}
                                            onClick={({ photo }) => handleClick(photo)}
                                            render={{
                                                photo: (props, { photo, width, height }) => 
                                                    photo.isCreateButton ? 
                                                        <CreateNewButton 
                                                            imageProps={props}
                                                            width={width}
                                                            height={height}
                                                        /> : 
                                                        <PhotoComponent 
                                                            photo={photo} 
                                                            imageProps={props}
                                                            width={width}
                                                            height={height}
                                                        />
                                            }}
                                            columns={breakpoints.columns}
                                            sizes={breakpoints.sizes}
                                            padding={2}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-col items-center justify-center py-16 bg-white/80 rounded-lg">
                                <h2 className="text-xl font-children font-semibold text-gray-800 mb-2">
                                    {t('gallery.no-sketches-yet')}
                                </h2>
                                <p className="text-gray-600 mb-6 text-center max-w-md">
                                    {t('gallery.start-your-creative-journey')}
                                </p>
                                <motion.a
                                    href="/create"
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                    className="inline-flex items-center gap-2 px-6 py-3 bg-blue-500 text-white rounded-lg 
                                             hover:bg-blue-600 transition-colors duration-200 font-children font-semibold
                                             shadow-md hover:shadow-lg"
                                >
                                    <Plus className="w-5 h-5" />
                                    {t('gallery.create-first-sketch')}
                                </motion.a>
                            </div>
                        )}
                    </div>

                    {/* Bottom Navigation - Only visible when scrolled */}
                    {!inView && pageInfo.totalPages > 1 && (
                        <motion.div 
                            className="sticky bottom-4 flex justify-center items-center gap-4 py-4"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.2 }}
                        >
                            <div className="bg-white/95 backdrop-blur-sm shadow-lg rounded-xl p-4 flex items-center gap-4">
                                <motion.button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                    whileHover={currentPage !== 1 ? { scale: 1.05 } : {}}
                                    whileTap={currentPage !== 1 ? { scale: 0.95 } : {}}
                                    className={`p-2 rounded-lg flex items-center justify-center font-children border
                                        ${currentPage === 1 
                                            ? 'bg-gray-100 text-gray-400 cursor-not-allowed border-gray-200' 
                                            : 'bg-white text-blue-500 hover:bg-blue-50 shadow-md hover:shadow-lg border-blue-200 hover:border-blue-300'}`}
                                >
                                    <ChevronLeft className="w-6 h-6" />
                                </motion.button>

                                <div className="flex justify-center gap-2 flex-wrap">
                                    {getPageNumbers().map((item, index) => (
                                        item.type === 'ellipsis' ? (
                                            <div 
                                                key={`ellipsis-${index}`}
                                                className="w-10 h-10 flex items-center justify-center text-blue-500 font-semibold"
                                            >
                                                •••
                                            </div>
                                        ) : (
                                            <motion.button
                                                key={`page-${item.number}`}
                                                onClick={() => handlePageChange(item.number)}
                                                whileHover={{ scale: 1.05 }}
                                                whileTap={{ scale: 0.95 }}
                                                className={`w-10 h-10 rounded-lg font-children font-semibold transition-colors
                                                          ${currentPage === item.number 
                                                            ? 'bg-blue-500 text-white shadow-md' 
                                                            : 'bg-white text-blue-500 hover:bg-blue-50 shadow-sm'}`}
                                            >
                                                {item.number}
                                            </motion.button>
                                        )
                                    ))}
                                </div>

                                <motion.button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === pageInfo.totalPages}
                                    whileHover={currentPage !== pageInfo.totalPages ? { scale: 1.05 } : {}}
                                    whileTap={currentPage !== pageInfo.totalPages ? { scale: 0.95 } : {}}
                                    className={`p-2 rounded-lg flex items-center justify-center font-children border
                                        ${currentPage === pageInfo.totalPages 
                                            ? 'bg-gray-100 text-gray-400 cursor-not-allowed border-gray-200' 
                                            : 'bg-white text-blue-500 hover:bg-blue-50 shadow-md hover:shadow-lg border-blue-200 hover:border-blue-300'}`}
                                >
                                    <ChevronRight className="w-6 h-6" />
                                </motion.button>
                            </div>
                        </motion.div>
                    )}
                </div>
            </div>
        </ErrorBoundary>
    );
};

export default SketchbookGallery;
