import React from 'react';
import { motion } from 'framer-motion';
import { Pencil, Wand2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ScribbleText from '../UI/ui_scribble_text.component';

const CreationButton = ({ icon: Icon, title, description, onClick, color }) => (
    <motion.button
        className={`w-full aspect-square rounded-2xl p-8 ${color} shadow-lg hover:shadow-xl
                   flex flex-col items-center justify-center gap-6 group
                   transition-colors duration-300 relative overflow-hidden`}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
            type: "spring",
            stiffness: 260,
            damping: 20
        }}
        onClick={onClick}
    >
        <motion.div 
            className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            initial={false}
            style={{
                background: 'radial-gradient(circle at center, rgba(255,255,255,0.1) 0%, transparent 70%)'
            }}
        />
        
        <motion.div
            className="relative z-10 flex flex-col items-center text-center gap-4"
            initial={false}
            whileHover={{ y: -5 }}
            transition={{ type: "spring", stiffness: 400, damping: 17 }}
        >
            <div className="p-4 bg-white/20 rounded-xl backdrop-blur-sm">
                <Icon className="w-12 h-12 text-white" strokeWidth={1.5} />
            </div>
            
            <div className="space-y-2">
                <h3 className="text-2xl font-children font-bold text-white drop-shadow-md">
                    {title}
                </h3>
                <p className="text-white/90 text-sm max-w-[200px] font-children">
                    {description}
                </p>
            </div>
        </motion.div>
    </motion.button>
);

CreationButton.propTypes = {
    icon: PropTypes.elementType.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired
};

const CreationView = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleManualMode = () => {
        navigate('/create/sketch');
    };

    const handleAIMode = () => {
        navigate('/create/ai');
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                {/* Header */}
                <motion.div 
                    className="text-center mb-12"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    <div className="flex justify-center mb-4">
                        <ScribbleText
                            text={t('creation.choose-your-creation-mode')}
                            sizeFactor={0.6}
                            fillColor="#027a9f"
                            strokeColor="#00a4d7"
                            roughness={0.6}
                            strokeWidth={1.5}
                            animate={true}
                        />
                    </div>
                    <p className="text-gray-600 text-lg font-children">
                        {t('creation.select-mode-description')}
                    </p>
                </motion.div>

                {/* Buttons Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto px-4">
                    <CreationButton
                        icon={Pencil}
                        title={t('creation.manual-mode')}
                        description={t('creation.manual-mode-description')}
                        color="bg-gradient-to-br from-blue-500 to-blue-600"
                        onClick={handleManualMode}
                    />
                    
                    <CreationButton
                        icon={Wand2}
                        title={t('creation.ai-mode')}
                        description={t('creation.ai-mode-description')}
                        color="bg-gradient-to-br from-purple-500 to-purple-600"
                        onClick={handleAIMode}
                    />
                </div>
            </div>
        </div>
    );
};

export default CreationView;
