import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getUserData } from '../../services/auth.db.service';
import BurgerMenu from './burger_menu.component';
import DesktopNav from './desktop_nav.component';

const Navbar = ({ isLoading, isEditing }) => {
    const [userData, setUserData] = useState(null);
    const [isCheckingAuth, setIsCheckingAuth] = useState(true);

    useEffect(() => {
        const checkUserData = async () => {
            try {
                const data = await getUserData();
                setUserData(data);
            } catch (error) {
                console.error('Error fetching user data:', error);
                setUserData(null);
            } finally {
                setIsCheckingAuth(false);
            }
        };

        checkUserData();
    }, []);

    const shouldHideNav = isLoading || (isEditing && window.location.pathname === '/create');

    if (shouldHideNav) return null;

    return (
        <>

            {/* Desktop Navigation - Hidden on mobile */}
            <div className="hidden md:block">
                <DesktopNav userData={userData} isCheckingAuth={isCheckingAuth} />
            </div>

            {/* Mobile Navigation - Hidden on desktop */}
            <div className="md:hidden">
                <BurgerMenu userData={userData} isCheckingAuth={isCheckingAuth} />
            </div>
        </>
    );
};

Navbar.propTypes = {
    isEditing: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
    isLoading: state.book.isLoading,
    isEditing: state.book.isEditing
});

export default connect(mapStateToProps)(withTranslation()(Navbar)); 