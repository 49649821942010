export const GALLERY_CONFIG = {
    ITEMS_PER_PAGE: 15, // Default number of items to show per page
    BREAKPOINTS: {
        MOBILE: 640,
        TABLET: 1024,
        DESKTOP: 1280
    },
    COLUMNS: {
        MOBILE: 2,
        TABLET: 3,
        DESKTOP: 4
    }
}; 