import { openDB } from 'idb';
import { GALLERY_CONFIG } from '../config/gallery.config';

// Constants
const DB_NAME = 'sketchCache';
const DB_VERSION = 1;
const STORE_NAME = 'sketches';
const METADATA_STORE = 'metadata';
export const URL_VALIDITY_DURATION = 30 * 60 * 1000; // 30 minutes in milliseconds
export const DEFAULT_ITEMS_PER_PAGE = GALLERY_CONFIG.ITEMS_PER_PAGE;
export const DEFAULT_PAGE = 1;

// Initialize IndexedDB with indexes
const initDB = async () => {
  return openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      // Create sketches store with indexes if it doesn't exist
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        const store = db.createObjectStore(STORE_NAME, { keyPath: '_id' });
        
        // Create indexes for efficient querying
        store.createIndex('createdAt', 'createdAt');
        store.createIndex('prompt', 'prompt');
        store.createIndex('fetchedAt', 'fetchedAt');
      }

      // Create metadata store if it doesn't exist
      if (!db.objectStoreNames.contains(METADATA_STORE)) {
        db.createObjectStore(METADATA_STORE, { keyPath: 'key' });
      }
    },
  });
};

// Update gallery metadata
export const updateGalleryMetadata = async ({ totalItems, totalPages }) => {
  try {
    const db = await initDB();
    const tx = db.transaction(METADATA_STORE, 'readwrite');
    const store = tx.objectStore(METADATA_STORE);
    
    await store.put({
      key: 'galleryStats',
      totalItems,
      totalPages,
      lastUpdated: new Date().toISOString()
    });
    
    await tx.done;
  } catch (error) {
    console.error('Error updating gallery metadata:', error);
  }
};

// Get gallery metadata
export const getGalleryMetadata = async () => {
  try {
    const db = await initDB();
    const tx = db.transaction(METADATA_STORE, 'readonly');
    const store = tx.objectStore(METADATA_STORE);
    
    const metadata = await store.get('galleryStats');
    await tx.done;
    
    return metadata;
  } catch (error) {
    console.error('Error getting gallery metadata:', error);
    return null;
  }
};

// Check if a URL is stale based on fetchedAt timestamp
export const isUrlStale = (fetchedAt) => {
  if (!fetchedAt) return true;
  return Date.now() - new Date(fetchedAt).getTime() > URL_VALIDITY_DURATION;
};

// Get the latest cached sketch's createdAt timestamp
export const getLatestSketchTimestamp = async () => {
  try {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readonly');
    const store = tx.objectStore(STORE_NAME);
    const index = store.index('createdAt');
    
    // Get the latest sketch by createdAt
    const cursor = await index.openCursor(null, 'prev');
    await tx.done;
    
    return cursor?.value?.createdAt || new Date(0).toISOString();
  } catch (error) {
    console.error('Error getting latest sketch timestamp:', error);
    return new Date(0).toISOString();
  }
};

// Get sketches for a specific page
export const getPageSketches = async (page = DEFAULT_PAGE, itemsPerPage = DEFAULT_ITEMS_PER_PAGE) => {
  try {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readonly');
    const store = tx.objectStore(STORE_NAME);
    const index = store.index('createdAt');
    
    // Calculate skip value for pagination
    const skip = (page - 1) * itemsPerPage;
    
    // Get sketches for current page
    const sketches = [];
    let counter = 0;
    let cursor = await index.openCursor(null, 'prev');
    
    while (cursor && counter < skip + itemsPerPage) {
      if (counter >= skip) {
        sketches.push(cursor.value);
      }
      counter++;
      cursor = await cursor.continue();
    }
    
    await tx.done;

    // Get metadata for total counts
    const metadata = await getGalleryMetadata();
    
    return {
      sketches,
      pageInfo: {
        totalItems: metadata?.totalItems || 0,
        totalPages: metadata?.totalPages || 1,
        currentPage: page,
        itemsPerPage
      }
    };
  } catch (error) {
    console.error('Error getting page sketches:', error);
    throw error;
  }
};

// Insert or update sketches in the cache
export const upsertSketches = async (sketches) => {
  try {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    
    // Add or update each sketch
    await Promise.all(
      sketches.map(sketch => {
        // Only update fetchedAt if we have a presignedUrl
        const sketchToStore = sketch.presignedUrl ? {
          ...sketch,
          fetchedAt: sketch.fetchedAt || new Date().toISOString()
        } : sketch;
        
        return store.put(sketchToStore);
      })
    );
    
    await tx.done;
  } catch (error) {
    console.error('Error upserting sketches:', error);
    throw error;
  }
};

// Get sketches with stale URLs
export const getStaleSketches = async () => {
  try {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readonly');
    const store = tx.objectStore(STORE_NAME);
    
    const staleSketches = [];
    let cursor = await store.openCursor();
    
    while (cursor) {
      const sketch = cursor.value;
      // Consider a sketch stale if it has no URL or its URL is stale
      if (!sketch.presignedUrl || isUrlStale(sketch.fetchedAt)) {
        staleSketches.push(sketch);
      }
      cursor = await cursor.continue();
    }
    
    await tx.done;
    return staleSketches;
  } catch (error) {
    console.error('Error getting stale sketches:', error);
    return [];
  }
};

// Search sketches by prompt
export const searchSketches = async (query, page = DEFAULT_PAGE, itemsPerPage = DEFAULT_ITEMS_PER_PAGE) => {
  try {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readonly');
    const store = tx.objectStore(STORE_NAME);
    
    const matchingSketches = [];
    let totalMatches = 0;
    let cursor = await store.openCursor();
    
    // Calculate skip value for pagination
    const skip = (page - 1) * itemsPerPage;
    
    while (cursor) {
      const sketch = cursor.value;
      if (sketch.prompt.toLowerCase().includes(query.toLowerCase())) {
        totalMatches++;
        if (totalMatches > skip && matchingSketches.length < itemsPerPage) {
          matchingSketches.push(sketch);
        }
      }
      cursor = await cursor.continue();
    }
    
    await tx.done;
    
    return {
      sketches: matchingSketches,
      pageInfo: {
        totalItems: totalMatches,
        totalPages: Math.ceil(totalMatches / itemsPerPage),
        currentPage: page,
        itemsPerPage
      }
    };
  } catch (error) {
    console.error('Error searching sketches:', error);
    throw error;
  }
};

// Clear all cached data
export const clearCache = async () => {
  try {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    await store.clear();
    await tx.done;
  } catch (error) {
    console.error('Error clearing cache:', error);
    throw error;
  }
}; 