import { bubble as Menu } from 'react-burger-menu';
import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { handleLogout, switchAccount } from "../../Hooks/LoginHandler";
import { withTranslation } from 'react-i18next';
import LanguageChange from './language_change.component';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setAskFeedback } from '../../redux/websiteSlice';
import { LogOut, LogIn, Home, Info, SwitchCamera, BookCopy, BookPlus, UserCircle, Shield, MessageCircle } from 'lucide-react';

const BurgerMenu = ({ t, isLoading, isEditing, setAskFeedback, userData, isCheckingAuth }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);
    
    const toggleMenu = () => {
        setIsOpen(prev => !prev);
    }

    const closeMenu = () => {
        setIsOpen(false);
    }

    const handleFeedback = () => {
        setAskFeedback(true);
        closeMenu();
    }

    const getMenuLink = (url, name, customClass, icon) => {
        const isActive = location.pathname === url;
        const baseClasses = "flex items-center justify-start w-full px-4 py-3 rounded-lg text-lg font-medium transition-all duration-200";
        
        const activeClasses = isActive ? "border-l-4 border-2 -ml-[2px] border-blue-500" : "";
        
        let finalCustomClass = customClass;
        if (isActive) {
            if (customClass.includes('bg-blue-200')) {
                finalCustomClass = customClass.replace('bg-blue-200', 'bg-blue-300');
            } else if (customClass.includes('bg-green-200')) {
                finalCustomClass = customClass.replace('bg-green-200', 'bg-green-300');
            } else if (customClass.includes('bg-purple-200')) {
                finalCustomClass = customClass.replace('bg-purple-200', 'bg-purple-300');
            }
        }

        return (
            <Link
                onClick={closeMenu}
                to={url}
                className={`${baseClasses} ${activeClasses} ${finalCustomClass}`}
            >
                {icon && <span className="mr-2">{icon}</span>}
                {name}
            </Link>
        );
    };

    const UserSection = () => {
        if (!userData) {
            return getMenuLink('/login', t('login.login'), 'bg-white text-gray-700 hover:bg-gray-100 border border-gray-200', <LogIn className="w-5 h-5" />);
        }

        const emailUsername = userData.email.split('@')[0];
        const emailDomain = '@' + userData.email.split('@')[1];

        return (
            <div className="relative mb-2" ref={dropdownRef}>
                <button
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    className="flex items-center justify-between w-full px-4 py-3 text-gray-700 bg-blue-200 hover:bg-blue-300 rounded-lg font-medium transition-all duration-200"
                >
                    <div className="flex items-center min-w-0">
                        <UserCircle className="w-5 h-5 mr-2 flex-shrink-0 text-blue-800" />
                        <span className="truncate text-blue-700 text-sm">
                            <span className="truncate">{emailUsername}</span>
                            <span className="hidden sm:inline">{emailDomain}</span>
                        </span>
                    </div>
                    <svg 
                        className={`w-4 h-4 text-blue-800 transition-transform duration-200 flex-shrink-0 ml-2 ${isDropdownOpen ? 'rotate-180' : ''}`}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
                </button>

                {isDropdownOpen && (
                    <div className="absolute bottom-full left-0 right-0 mb-2 bg-white rounded-lg shadow-lg border border-gray-200 py-2">
                        <Link
                            to="/profile"
                            className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-200"
                            onClick={() => {
                                setIsDropdownOpen(false);
                                closeMenu();
                            }}
                        >
                            <UserCircle className="w-4 h-4 mr-2" />
                            {t('login.profile')}
                        </Link>
                        <button
                            onClick={() => {
                                switchAccount();
                                setIsDropdownOpen(false);
                                closeMenu();
                            }}
                            className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-200"
                        >
                            <SwitchCamera className="w-4 h-4 mr-2" />
                            {t('login.switch-account')}
                        </button>
                        <div className="h-px bg-gray-200 my-1" />
                        <button
                            onClick={() => {
                                handleLogout(true);
                                setIsDropdownOpen(false);
                                closeMenu();
                            }}
                            className="flex items-center w-full px-4 py-2 text-sm text-red-600 hover:bg-red-50 transition-colors duration-200"
                        >
                            <LogOut className="w-4 h-4 mr-2" />
                            {t('login.logout')}
                        </button>
                    </div>
                )}
            </div>
        );
    };

    const TopNavIcons = (className) => (
        <div className={`absolute top-2 md:top-2 flex items-center space-x-4 z-50 ${className}`}>
            <LanguageChange />
            
            <button
                onClick={() => window.location.href = '/about'}
                className={`flex items-center justify-center w-10 h-10 rounded-full bg-blue-600/20 hover:bg-blue-600/30 transition-all duration-200 
                    ${location.pathname === '/about' ? 'border-2 border-blue-500 bg-blue-600/60' : ''}`}
            >
                <Info className="w-5 h-5 text-blue-500" />
            </button>   

            <button
                onClick={handleFeedback}
                className={`flex items-center justify-center w-10 h-10 rounded-full bg-blue-600/20 hover:bg-blue-600/30 transition-all duration-200`}
            >
                <MessageCircle className="w-5 h-5 text-blue-500" />
            </button>
        </div>
    );

    const shouldHideMenu = isLoading || (isEditing && location.pathname === '/create');

    if (shouldHideMenu) return null;

    if (isCheckingAuth) {
        return <TopNavIcons className="z-50" />;
    }
    
    return (
        <>           
            <div className="fixed top-0 left-0 z-50">
                <Menu
                    isOpen={isOpen}
                    onOpen={toggleMenu}
                    onClose={toggleMenu}
                    burgerButtonClassName="fixed w-10 h-8 left-4 top-4 md:left-8 md:top-8 transition-colors duration-200 hover:opacity-80"
                    burgerBarClassName="bg-blue-500 rounded-sm h-0.5 mx-auto my-1"
                    crossButtonClassName="flex items-center justify-center w-8 h-8 hover:bg-gray-100 rounded-full cursor-pointer transition-colors duration-200"
                    crossClassName="bg-gray-500"
                    menuClassName="bg-white p-6 w-64 h-full flex flex-col"
                    morphShapeClassName="fill-white"
                    overlayClassName="bg-gray-800/50 backdrop-blur-sm"
                    itemListClassName="flex flex-col h-full"
                >
                    <div className="relative z-[50] flex flex-col h-full">
                        <div className="relative z-[60] mb-16">
                            <TopNavIcons />
                        </div>
                        <div className="flex flex-col space-y-4">                            
                            {getMenuLink('/', t('login.home'), 'bg-blue-200 hover:bg-blue-300 text-blue-700', <Home className="w-5 h-5" />)}
                            {userData && getMenuLink('/gallery', t('login.my-gallery'), 'bg-blue-200 hover:bg-blue-300 text-blue-700', <BookCopy className="w-5 h-5" />)}
                            {userData && getMenuLink('/create', t('login.create'), 'bg-blue-200 hover:bg-blue-300 text-blue-700', <BookPlus className="w-5 h-5" />)}
                            {userData?.isAdmin && (
                                <>
                                    <div className="h-1 bg-gray-200"></div>
                                    {getMenuLink('/admin', t('login.admin'), 'bg-purple-200 hover:bg-purple-300 text-purple-700', <Shield className="w-5 h-5" />)}
                                </>
                            )}
                        </div>
                        <div className="absolute bottom-0 left-0 right-0">
                            <UserSection />
                        </div>
                    </div>
                </Menu>
            </div>
        </>
    );
};

BurgerMenu.propTypes = {
    t: PropTypes.func.isRequired,
    setAskFeedback: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    userData: PropTypes.object,
    isCheckingAuth: PropTypes.bool.isRequired
};

const mapDispatchToProps = {
    setAskFeedback
};

const mapStateToProps = (state) => ({
    isLoading: state.book.isLoading,
    isEditing: state.book.isEditing
});

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(withTranslation()(BurgerMenu));