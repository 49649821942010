import React, { useState, useEffect } from 'react';
import { getUserData } from '../../services/auth.db.service';
import { User, Mail, Coins } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const Profile = () => {
    const { t } = useTranslation();
    const [userData, setUserData] = useState(null);
    const [credits, setCredits] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [redeemAnimation, setRedeemAnimation] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const storedData = await getUserData();
                if (storedData) {
                    setUserData(storedData);
                    setCredits(storedData.credits || 0);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserData();
    }, []);

    if (isLoading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
            </div>
        );
    }

    if (!userData) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="text-gray-600">No user data available</div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            {/* Account Information Container */}
            <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl p-6 mb-8">
                <div className="text-center mb-8">
                    <div className="h-24 w-24 rounded-full bg-blue-100 mx-auto flex items-center justify-center">
                        <User className="h-12 w-12 text-blue-600" />
                    </div>
                    <h2 className="mt-4 text-2xl font-bold text-gray-900">{userData.full_name}</h2>
                </div>

                <div className="space-y-4">
                    <div className="flex items-center p-4 bg-gray-50 rounded-lg">
                        <User className="h-6 w-6 text-blue-600 mr-3" />
                        <div>
                            <div className="text-sm text-gray-500">{t('profile.name')}</div>
                            <div className="text-gray-900">{userData.full_name}</div>
                        </div>
                    </div>
                    <div className="flex items-center p-4 bg-gray-50 rounded-lg">
                        <Mail className="h-6 w-6 text-blue-600 mr-3" />
                        <div>
                            <div className="text-sm text-gray-500">{t('profile.email')}</div>
                            <div className="text-gray-900">{userData.email}</div>
                        </div>
                    </div>

                    <div className={`flex items-center p-6 ${credits === 0 ? 'bg-red-50' : 'bg-blue-50'} rounded-lg relative overflow-hidden`}>
                        <Coins className="h-8 w-8 text-blue-600 mr-4" />
                        <div className="flex-grow">
                            <div className="text-base text-gray-500 mb-1">{t('profile.credits')}</div>
                            <div className="flex items-baseline">
                                <span className="text-3xl font-bold text-blue-600">
                                    {credits}
                                </span>
                                {redeemAnimation && (
                                    <span className="ml-3 text-xl font-bold text-green-500 animate-bounce">
                                        +{redeemAnimation.creditsAdded}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="flex items-center ml-4">
                            <button
                                onClick={() => window.location.href = '/payment'}
                                className="bg-green-500 border-b-4 border-green-600 text-white px-4 py-3 rounded-lg hover:bg-green-600 
                                    transition-all duration-200 transform hover:scale-105 shadow-sm 
                                    hover:shadow-md flex items-center font-medium"
                            >
                                <Coins className="h-4 w-4 mr-2" />
                                Get Credits
                            </button>
                        </div>
                        {redeemAnimation && (
                            <div className="absolute inset-0 bg-green-100 opacity-20 animate-pulse" />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
