import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import useSketchbook from '../../Hooks/Sketchbook/useSketchbook';
import CreationPageInput from '../Sketchbook/CreationPageInput.component';
import CreationPageEdition from '../Sketchbook/CreationPageEdition.component';

const CreationPage = () => {
    const [prompt, setPrompt] = useState('');
    const [selectedMode, setSelectedMode] = useState('simple');
    const [selectedOrientation, setSelectedOrientation] = useState('portrait');
    const [showEdition, setShowEdition] = useState(false);
    
    const { 
        isLoading, 
        error, 
        generatedImage, 
        remainingCredits,
        sketchId,
        generateSketch,
        editFillSketch,
        clearError
    } = useSketchbook();

    useEffect(() => {
        // Clear error when prompt, mode, or orientation changes
        if (error) clearError();
    }, [prompt, selectedMode, selectedOrientation, error, clearError]);

    useEffect(() => {
        // Show edition view when image is generated
        if (generatedImage && !isLoading) {
            setShowEdition(true);
        }
    }, [generatedImage, isLoading]);

    const handleGenerate = async () => {
        try {
            await generateSketch(prompt, selectedMode, selectedOrientation);
        } catch (error) {
            console.log('Generation failed:', error.message);
        }

    };

    const handleEditFill = async (params) => {
        try {
            await editFillSketch(params);
        } catch (error) {
            console.log('Edit fill failed:', error.message);
        }

    };

    const handleBack = () => {
        setShowEdition(false);
        setPrompt('');
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-50/80 to-white/30">
            <AnimatePresence mode="wait">
                {!showEdition ? (
                    <motion.div
                        key="input"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <CreationPageInput
                            prompt={prompt}
                            setPrompt={setPrompt}
                            selectedMode={selectedMode}
                            setSelectedMode={setSelectedMode}
                            selectedOrientation={selectedOrientation}
                            setSelectedOrientation={setSelectedOrientation}
                            handleGenerate={handleGenerate}
                            isLoading={isLoading}
                            error={error}
                            remainingCredits={remainingCredits}
                        />
                    </motion.div>
                ) : (
                    <motion.div
                        key="edition"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <CreationPageEdition
                            generatedImage={generatedImage}
                            isLoading={isLoading}
                            onBack={handleBack}
                            sketchId={sketchId}
                            handleEditFill={handleEditFill}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default CreationPage;
