import React, { Suspense, useEffect, useState } from 'react';
import { RouterProvider, createBrowserRouter, Outlet, ScrollRestoration, Navigate } from 'react-router-dom';
import LoginView from "./Components/Views/Login.view";
import Navbar from "./Components/Navbar/navbar.component";
import './Styles/main.css';
import MainView from "./Components/Views/Home.view";
import Creation from './Components/Views/Creation.view';
import CreationPage from './Components/Views/CreationPage.view';
import ModifyBook from './Components/CreateBook/ModifyBook';
import Popup from "./Components/Popup";
import ErrorBoundaryWrapper from './Components/ErrorBoundary';
import Feedback from './Components/Feedback/feedback';
import InteractiveDemo from './Components/LandingPage/InteractiveDemo';
import About from './Components/Views/About.view';
import AuthSuccess from './Components/Auth/AuthSuccess';
import Profile from "./Components/Views/Profile.view";
import Payment from "./Components/Views/Payment.view";
import PaymentSuccess from "./Components/Views/PaymentSuccess.view";
import Admin from './Components/Views/Admin.view';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, browserHistory, setAuthenticatedUser } from './utils/appInsights';
import { usePageTracking } from './utils/usePageTracking';
import PropTypes from 'prop-types';
import { useAppInsights } from './utils/useAppInsights';
import SketchbookGallery from './Components/Views/Gallery.view';
import { getUserData } from './services/auth.db.service';

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const userData = await getUserData();
        setIsAuthenticated(!!userData);
      } catch (error) {
        console.error('Error checking authentication:', error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, []);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const AdminRoute = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const userData = await getUserData();
        setIsAdmin(!!userData?.isAdmin);
      } catch (error) {
        console.error('Error checking admin status:', error);
        setIsAdmin(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkAdminStatus();
  }, []);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  if (!isAdmin) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const AppLayout = () => {    
    usePageTracking();
    
    useEffect(() => {
        const updateUserData = async () => {
            const userData = await getUserData();
            setAuthenticatedUser(userData);
        };
        
        updateUserData();
    }, []);

    return (
        <ErrorBoundaryWrapper>
            <div className="grid grid-rows-[auto_1fr] min-h-screen">
                <header className="sticky top-0 z-50">
                    <Navbar />
                </header>
                <main className="relative">
                    <Popup />
                    <Feedback />
                    <ScrollRestoration />
                    <Outlet />
                </main>
            </div>
        </ErrorBoundaryWrapper>
    );
};

const errorElement = () => {
  return (
    <ErrorBoundaryWrapper>
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Something went wrong</h2>
          <div className="flex flex-col space-y-2">
            <button 
              onClick={() => window.location.reload()} 
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition-colors"
            >
              Try again
            </button>
            <button 
              onClick={() => window.location.href = '/'} 
              className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded transition-colors"
            >
              Go home
            </button>
          </div>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

const router = createBrowserRouter([
  {
    element: <ErrorBoundaryWrapper>
      <AppLayout />
    </ErrorBoundaryWrapper>,
    errorElement: errorElement(),
    children: [
      {
        element: <ProtectedRoute><Outlet /></ProtectedRoute>,
        children: [
          { 
            path: '/create', 
            element: <ErrorBoundaryWrapper><Creation /></ErrorBoundaryWrapper>,
          },
          { 
            path: '/create/sketch', 
            element: <ErrorBoundaryWrapper><CreationPage /></ErrorBoundaryWrapper>,
          },
          { 
            path: '/create/ai', 
            element: <ErrorBoundaryWrapper><ModifyBook /></ErrorBoundaryWrapper>,
          },
          { 
            path: '/gallery', 
            element: <ErrorBoundaryWrapper><SketchbookGallery /></ErrorBoundaryWrapper>,
          },
          { 
            path: '/profile', 
            element: <ErrorBoundaryWrapper><Profile /></ErrorBoundaryWrapper>,
          },
          {
            path: '/admin',
            element: <AdminRoute><ErrorBoundaryWrapper><Admin /></ErrorBoundaryWrapper></AdminRoute>,
          },          
          {
            path: '/payment',
            element: <ErrorBoundaryWrapper><Payment /></ErrorBoundaryWrapper>,
          },
          {
            path: '/payment/success',
            element: <ErrorBoundaryWrapper><PaymentSuccess /></ErrorBoundaryWrapper>,
          },
          {
            path: '/payment/cancel',
            element: <ErrorBoundaryWrapper><Navigate to="/payment" replace /></ErrorBoundaryWrapper>,
          },         
        ]
      },
      { 
        path: '/about', 
        element: <ErrorBoundaryWrapper><About /></ErrorBoundaryWrapper>,
      },
      { 
        path: '/login', 
        element: <ErrorBoundaryWrapper><LoginView /></ErrorBoundaryWrapper>,
      },    
      { 
        path: '/', 
        element: <ErrorBoundaryWrapper><MainView /></ErrorBoundaryWrapper>,
      },
      { 
        path: '/demo', 
        element: <ErrorBoundaryWrapper><InteractiveDemo /></ErrorBoundaryWrapper>,
      },
      { 
        path: '/auth-success', 
        element: <AuthSuccess />,
      },
    ]
  }
], {
    history: browserHistory
});

ProtectedRoute.propTypes = {
    children: PropTypes.node.isRequired
};

AdminRoute.propTypes = {
    children: PropTypes.node.isRequired
};

export default function TranslatedApp() {
    const { trackException } = useAppInsights();
    return (
        <Suspense fallback="...loading page...">
            <AppInsightsErrorBoundary 
                onError={(error) => {
                    trackException(error, 1);
                    return errorElement();
                }} 
                appInsights={reactPlugin}
            >
                <RouterProvider router={router} />
            </AppInsightsErrorBoundary>
        </Suspense>
    );
}
