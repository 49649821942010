import { useState } from 'react';
import api from '../ApiHandler';
import { useAppInsights } from '../../utils/useAppInsights';

const useSketchEdition = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [generatedImage, setGeneratedImage] = useState(null);
    const [sketchId, setSketchId] = useState(null);
    const [error, setError] = useState(null);
    const [sketches, setSketches] = useState([]);
    const { trackEvent } = useAppInsights();

    const generateSketch = async (prompt, mode = 'simple', orientation = 'portrait') => {
        if (!prompt || prompt.trim() === '') {
            setError('Please provide a description');
            return null;
        }

        setIsLoading(true);
        setError(null);
        const startTime = Date.now();

        try {
            const response = await api.post('image/sketch/generate', {
                prompt,
                mode,
                orientation
            });

            const { image, sketchId, ...rest } = response.data;
            setGeneratedImage(image);
            setSketchId(sketchId);


            trackEvent('sketch_generated', {
                mode,
                duration: Date.now() - startTime
            });

        } catch (error) {
            console.error('Error generating sketch:', error);
            const errorMessage = error.response?.data?.error || 'Failed to generate sketch';
            setError(errorMessage);
            setGeneratedImage(null);
            throw new Error(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    const editFillSketch = async (params, mode = 'good') => {
        const { prompt, baseImage, maskImage, parentId } = params;
        const startTime = Date.now();

        if (!prompt || !baseImage || !maskImage || !parentId) {
            throw new Error('Missing required parameters for edit fill');            
        }

        try {
            const response = await api.post('image/sketch/edit-fill', {
                prompt,
                baseImage,
                maskImage,
                parentId,
                mode

            });

            const { image, sketchId, ...rest } = response.data;
            setGeneratedImage(image);
            setSketchId(sketchId);

            trackEvent('sketch_edited', {
                mode,
                duration: Date.now() - startTime
            });

        } catch (error) {
            console.error('Error in editFillSketch:', error);
            throw error;
        }

    };

    const clearError = () => setError(null);
    const clearGeneratedImage = () => setGeneratedImage(null);

    return {
        isLoading,
        error,
        sketches,
        generatedImage,
        sketchId,
        generateSketch,
        editFillSketch,
        clearError,
        clearGeneratedImage

    };
};

export default useSketchEdition;
