import {useTranslation} from "react-i18next";
import {handleLogout} from "../../Hooks/LoginHandler";
import React from "react";
import { LogOut } from 'lucide-react';
import ScribbleText from "../UI/ui_scribble_text.component";

const LoginForm = () => {
    const { t } = useTranslation();

    return (
        <div className="min-h-screen flex items-center justify-center bg-paper relative">
            {/* Paper texture overlay with gradient */}
            <div className="absolute inset-0 pointer-events-none bg-gradient-to-b from-blue-50/50 to-blue-300/50" />
            <div className="w-full max-w-md mx-4 p-8 bg-white/95 backdrop-blur-sm rounded-lg shadow-xl relative">
                <div className="mb-8 flex justify-center">
                    <ScribbleText
                        text={t('login.goodbye')}
                        sizeFactor={0.6}
                        fillColor="#027a9f"
                        strokeColor="#00a4d7"
                        roughness={1.25}
                        strokeWidth={2}
                        animate={true}
                    />
                </div>
                
                <button
                    onClick={() => handleLogout()}
                    className="w-full py-3 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-all duration-300 transform hover:scale-[1.02] font-children font-semibold tracking-wide flex items-center justify-center gap-2"
                >
                    <LogOut className="h-5 w-5" />
                    <span>{t('login.logout')}</span>
                </button>
            </div>
        </div>
    );
}

export default LoginForm