import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Download, Printer, PenLine, ArrowLeft } from 'lucide-react';
import EditionPainting from './EditionPainting.component';
import PropTypes from 'prop-types';

const CreationPageEdition = ({ 
    generatedImage, 
    isLoading, 
    onBack, 
    sketchId, 
    handleEditFill,
    creationDate = new Date().toLocaleDateString(),
    userPrompt = 'Your artwork' 
}) => {
    const [showPaintingMode, setShowPaintingMode] = useState(false);

    const handleDownload = () => {
        if (generatedImage) {
            const link = document.createElement('a');
            link.href = generatedImage;
            link.download = `sketch-${Date.now()}.png`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handlePrint = () => {
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
            <html>
                <head>
                    <title>Print Sketch</title>
                    <style>
                        @page {
                            margin: 0;
                            size: auto;
                        }
                        body {
                            margin: 0;
                            padding: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            min-height: 100vh;
                            background: white;
                        }
                        img {
                            max-width: 100%;
                            max-height: 100vh;
                            object-fit: contain;
                            display: block;
                        }
                        @media print {
                            html, body {
                                height: 100%;
                                margin: 0 !important;
                                padding: 0 !important;
                            }
                            img {
                                page-break-inside: avoid;
                            }
                        }
                    </style>
                </head>
                <body>
                    <img 
                        src="${generatedImage}" 
                        onload="setTimeout(() => { window.print(); window.close(); }, 200)"
                    >
                </body>
            </html>
        `);
        printWindow.document.close();
    };

    const topButtons = [
        { 
            icon: PenLine, 
            label: 'Modify', 
            onClick: () => setShowPaintingMode(true), 
            description: 'Edit your artwork'
        }
    ];

    const utilityButtons = [
        {
            icon: Download,
            label: 'Download',
            onClick: handleDownload
        },
        {
            icon: Printer,
            label: 'Print',
            onClick: handlePrint
        }
    ];

    if (showPaintingMode) {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
            >
                <EditionPainting 
                    image={generatedImage}
                    onBack={() => setShowPaintingMode(false)}
                    sketchId={sketchId}
                    handleEditFill={handleEditFill}
                />
            </motion.div>
        );
    }

    return (
        <div className="min-h-screen">
            <div className="container mx-auto px-4 py-8 h-[calc(100vh-4rem)]">
                <div className="flex flex-col md:flex-row gap-8 h-full">
                    {/* Image Column with Back Button */}
                    <AnimatePresence mode="wait">
                        {generatedImage && !isLoading && (
                            <motion.div
                                key="generated-image"
                                layoutId="artwork-container"
                                className="w-full md:w-1/2 h-full flex flex-col gap-4"
                            >
                                <div className="relative w-full h-full max-h-[80vh] flex items-center justify-center my-auto">
                                    <motion.div
                                        layoutId="artwork-image"
                                        className="flex items-center justify-center relative"
                                        style={{
                                            height: '100%',
                                            width: 'auto',
                                            maxWidth: '100%'
                                        }}
                                    >
                                        <img
                                            src={generatedImage} 
                                            alt="Generated artwork"
                                            className="h-auto w-auto max-h-[80vh] object-contain rounded-xl shadow-lg border-4 border-blue-200"
                                        />
                                    </motion.div>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>

                    {/* Info Column */}
                    <motion.div
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.3, delay: 0.2 }}
                        className="w-full md:w-1/2 flex flex-col gap-6 p-4"
                    >
                        {/* Back Button */}
                        <button
                            onClick={onBack}
                            className="flex items-center gap-2 px-5 py-2.5 text-blue-700 hover:text-blue-800 
                                     bg-blue-50 hover:bg-blue-100 rounded-lg shadow-md hover:shadow-lg transition-all duration-200
                                     border-2 border-blue-300 hover:border-blue-500 w-fit font-medium"
                        >
                            <ArrowLeft className="w-5 h-5" />
                            <span className="font-children">Back</span>
                        </button>

                        {/* Info Panel */}
                        <div className="bg-white rounded-xl shadow-md p-6">
                            <div className="space-y-4">
                                <div className="flex justify-between items-start">
                                    <div className="space-y-3">
                                        <h2 className="text-2xl font-children font-semibold text-gray-800">
                                            {new Date(creationDate).toLocaleDateString('en-US', {
                                                weekday: 'long',
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                            })}
                                        </h2>
                                        <p className="font-children text-lg text-gray-700 font-medium">
                                            &ldquo;{userPrompt}&rdquo;
                                        </p>
                                    </div>
                                    <div className="flex gap-3">
                                        {utilityButtons.map((button) => {
                                            const Icon = button.icon;
                                            return (
                                                <button
                                                    key={button.label}
                                                    onClick={button.onClick}
                                                    className="w-16 h-16 flex items-center justify-center rounded-lg
                                                             bg-white hover:border-blue-300
                                                             transition-all duration-200 hover:shadow-md group border-2 border-b-4 border-blue-400"
                                                    title={button.label}
                                                >
                                                    <Icon className="w-6 h-6 text-blue-500 group-hover:text-blue-600" />
                                                </button>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Image Controls Panel */}
                        <div className="bg-white rounded-xl shadow-md p-6">
                            <div className="grid grid-cols-4 gap-4">
                                <button
                                    onClick={topButtons[0].onClick}
                                    className="w-24 h-24 flex flex-col items-center justify-center rounded-lg
                                             bg-blue-100 hover:bg-blue-200 border-2 border-b-4 border-blue-300
                                             transition-all duration-200 hover:shadow-lg group"
                                >
                                    <PenLine className="w-8 h-8 text-blue-600 group-hover:text-blue-700" />
                                    <span className="font-children text-xs font-medium text-blue-600 group-hover:text-blue-700 mt-1">
                                        {topButtons[0].label}
                                    </span>
                                </button>
                                {/* Future image control buttons will go here */}
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

CreationPageEdition.propTypes = {
    generatedImage: PropTypes.string,
    isLoading: PropTypes.bool,
    onBack: PropTypes.func.isRequired,
    sketchId: PropTypes.string,
    handleEditFill: PropTypes.func.isRequired,
    creationDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    userPrompt: PropTypes.string
};

export default CreationPageEdition;
