import api from "./ApiHandler"
import store, { resetPersistedState } from "../redux/store";
import { updateCredits } from '../redux/websiteSlice';
import { 
    saveRefreshToken, 
    saveAccessToken, 
    saveUserData, 
    updateUserCredits, 
    clearAuthData,
    getRefreshToken 
} from '../services/auth.db.service';
import { clearCache } from '../services/db.service';

const LOGIN_TIMEOUT = 3000

const handleLogin = (loginData) => {
    api.post('user/login', loginData, {timeout: LOGIN_TIMEOUT}).then(async (r)=> {
        if(!r) return;
        const { __v, ...user } = r.data.user;
        console.log('user: ', user);
        
        await saveAccessToken(r.data.token);
        await saveRefreshToken(r.data.refresh_token);
        await saveUserData(user);
        await updateUserCredits(user.credits);
        store.dispatch(updateCredits(user.credits));

        window.location.href = '/create';
    });
}

const switchAccount = async () => {
    try {
        // Clear all data before switching accounts
        await clearAuthData();
        await clearCache();
        await resetPersistedState();
        
        const baseUrl = window.location.origin.includes('localhost') 
            ? 'http://localhost:5000'
            : `${window.location.origin}/api`;
                
        window.location.href = `${baseUrl}/api/user/auth/google?prompt=select_account`;
    } catch (error) {
        console.error('Error during account switch:', error);
        // Even if clearing fails, try to switch account
        const baseUrl = window.location.origin.includes('localhost') 
            ? 'http://localhost:5000'
            : `${window.location.origin}/api`;
                
        window.location.href = `${baseUrl}/api/user/auth/google?prompt=select_account`;
    }
}

const handleLogout = async (goToMainMenu = false) => {
    const path = goToMainMenu ? "/":"/login"
    try {
        const refreshToken = await getRefreshToken();
        if (refreshToken) {
            // Send refresh token to backend for removal
            await api.post('/user/logout', { refreshToken });
        }
        // Clear all auth data from IndexedDB
        
        // Clear redux state
        store.dispatch(updateCredits(0));
        window.location.href = path;
    } catch (error) {
        console.error('Logout error:', error);
        // Even if backend call fails, clear local data
        window.location.href = path;
    }finally{
        await clearAuthData();
        await clearCache();
        await resetPersistedState();
    }
};

export {
    handleLogin,
    switchAccount,
    handleLogout
}